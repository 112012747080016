@use "../../utils/variables.scss";

.page {
    position: relative;
}

.page-content {
    min-height: calc(100vh - 803px);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
}

@media screen and (min-width: 900px) {
    .page-content {
        min-height: calc(100vh - 542px);
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 80px;
        padding-right: 80px;
        &.acasa {
            padding-left: 0px;
            padding-right: 0px; 
            .title-container {
              margin-left: 80px;
            }
        }
    }
}

.sponsor {
  position: fixed;
  left: 20px;
  bottom: 0;
  z-index: 999;
  height: 160px;
  cursor: pointer;
  filter: drop-shadow(3px 3px 5px #212121);
  @media screen and (min-width: 900px) {
    height: 200px;
  }
}

.pushable {
    background: rgb(87, 0, 0);
    border-radius: 50%;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 999;
    box-shadow: 2px 2px 5px rgb(0, 0, 0);
  }
  .front {
    display: block;
    padding: 22px 17px;
    border-radius: 50%;
    font-size: 1.25rem;
    background: variables.$traditional-red;
    color: white;
    font-weight: bold;
    transform: translateY(-7px);
    box-shadow: 2px 2px 5px rgb(52, 0, 0);
  }

  .front {
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }

  .pushable:hover .front {
    transform: translateY(-11px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }

  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }