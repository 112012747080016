@use "../../utils/variables";

.redbar {
    display: flex;
    justify-content: space-around;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 10px;
    padding-left: 40px;
    align-items: center;
    height: 60px;
    background-color: variables.$traditional-red;

    .svg-inline--fa {
        color: white;
        opacity: 0.7;
        height: 20px;
    }
    .svg-inline--fa:not(:last-child) {
        margin-right: 25px;
    }
    .svg-inline--fa:hover {
        cursor: pointer;
        opacity: 1;
        transition: 0.3s ease-in-out;
    }
    .flags {
        padding-top: 20px;
        height: inherit;
        display: flex;
        justify-content: space-between;
        width: 125px;
        .roFlag {
            height: 40px;
            box-shadow: 2px -1px 15px 1px rgb(255, 255, 255);
        }
        .ueFlag {
            height: 40px;
            box-shadow: -1px -1px 15px 1px rgb(255, 255, 255);
        }
    }
}
 
@media screen and (min-width: 900px) {

    .redbar {
        justify-content: space-between;
        padding: 0;
        padding-right: 4rem;
        padding-left: 4rem;
        height: 40px;
        //background-image: linear-gradient(variables.$traditional-red, #e00000df, variables.$traditional-red);
        
        .flags {
            padding-top: 5px;
            width: 110px;
            .roFlag {
                height: 35px;
            }
            .ueFlag {
                height: 35px;
            }
        }
    }
}
