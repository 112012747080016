.title-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    img {
            object-fit: contain;
            width: 350px;
            height: 200px;
        @media screen and (min-width: 900px) {
            width: 700px;
            height: 400px;
        }
        border-radius: 10px;
    }
}

.stire-content {
    .subtitle {
        font-weight: bold;
        margin-top: 20px;
    }
    .italic {
        font-style: italic;
    }
    .bold-italic {
        font-style: italic;
        font-weight: bold;
    }
    .bold {
        font-weight: bold;
    }
}
.author {
    opacity: 0.7;
    margin-top: 40px;
}

.article-video {
    width: 100%;
    height: 360px;
    @media screen and (min-width: 900px) {
        width: 640px;
    }
}

.date {
    color: rgb(150, 150, 150);
    font-size: 14px;
    margin-bottom: 20px;
}