@use "../../utils/variables";

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
        color: rgb(73, 73, 73);
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        &:hover, &:focus {
            color: black;
            transition: 0.3s ease-in-out;
        }
    }

    label{
        color: rgb(50, 50, 50);
        font-weight: bold;
    }
    label#first {
        margin-top: 20px;
    }
    input[type=text] {
        height: 25px;
        padding: 10px 10px;
        margin: 8px 0;
        border: 1px solid grey;
        border-radius: 4px;
        &:focus {
            outline-color: variables.$traditional-red
        }
    }
    textarea {
        padding: 10px 10px;
        margin: 8px 0;
        border: 1px solid grey;
        border-radius: 4px;
        &:focus {
            outline-color: variables.$traditional-red
        }
    }
    input[type=submit] {
        background-color: variables.$traditional-red;
        color: white;
        margin: 8px 0;
        padding: 10px 10px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        width: 200px;
        font-size: 13px;
        text-transform: uppercase;
        &:hover, &:focus {
            background-color: variables.$traditional-dark-red;
        }
        &:disabled {
            background-color: rgb(183, 183, 183);
            border: 1px solid rgb(116, 116, 116);
            cursor: context-menu;
        }
    }
}

@media screen and (min-width: 900px) {
    .contact-form {
        width: 40%;
    }
}