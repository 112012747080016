@use "../../utils/variables.scss";

.image-gallery-description {
  font-size: 20px !important;
  width: 100%;

  @media screen and (min-width: 900px) {
    font-size: 40px !important;
  }
}

.image-gallery-slide img {
  height: auto !important;
  @media screen and (min-width: 900px) {
    height: calc(100vh - 300px) !important;
  }
}

.carousel-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-gallery {
  width: 100%;
  @media screen and (min-width: 900px) {
    width: 80%;
  }
}