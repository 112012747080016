@use "../../utils/variables";

// MENU BUTTON STYLE
.menu {
    border-bottom: 1px solid rgb(214, 214, 214);
    &-button {
        position: fixed;
        cursor: pointer;
        top: 27px;
        left: 10px
    }
    
    
    &-button .middle {
        height: 4px;
        width: 30px;
        background-color: white;
        border-radius: 50px;
        position: absolute;
        transition: 0.3s ease-in-out;
    }
    
    
    &-button .top {
        height: 4px;
        width: 30px;
        background-color: white;
        border-radius: 50px;
        position: absolute;
        transition: 0.3s ease-in-out;
        bottom: 6px;
    }
    
    
    &-button .bottom {
        top: 10px;
        height: 4px;
        width: 30px;
        background-color: white;
        border-radius: 50px;
        position: absolute;
        transition: 0.3s ease-in-out;
    }
}

.menu.open .menu-button .top {
    transform: translate(0, 9px) rotate(-45deg);
}


.menu.open .menu-button .bottom  {
    transform: translate(0, -11px) rotate(45deg);
}


.menu.open .menu-button .middle  {
    opacity: 0;
}

// MENU LIST STYLE
.menu {
    &-list {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        top: 61px;
        left: -100%;
        height: calc(100vh - 60px);
        width: 100%;
        background-color: white;
        transition: left 0.6s ease-in-out;
        &_item {
            text-decoration: none;
            color: variables.$traditional-red;
            cursor: pointer; 
            position: relative;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: bold;
        }
        &_item.active::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1.5px;
            left: 0;
            bottom: 0;
            background-color: variables.$traditional-red;
            transform: scaleX(1);
            bottom: -5px;
        }
        &_item::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1.5px;
            left: 0;
            bottom: 0;
            background-color:  variables.$traditional-red;
            transition: transform 0.3s ease-in-out;
            transform: scaleX(0);
            transform-origin: bottom left;
            bottom: -5px;
        }
        &_item:hover::after {
            transform: scaleX(1);
        }
    }
}

.menu.open {
    .menu-list {
            left: 0;
    }
}


@media screen and (min-width: 900px) {
    .menu {
        background-color: white;
        display: flex;
        justify-content: center;
        top: 100px;
        &-button {
            display: none;
        }
        &-list {
            height: 80px;
            position: static;
            display: flex;
            flex-direction: row;
            transition: none;
            justify-content: center;
            
            &_item {
                color: variables.$traditional-red;
                cursor: pointer;
                font-size: 16px;
                font-weight: normal;
                &:not(:last-child) {
                    margin-right: 45px;
                }
            }
            &_item.active::after {
                background-color:  variables.$traditional-red;
            }
            &_item::after {
                background-color: variables.$traditional-red;
            }
        }
    }
}