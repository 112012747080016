.gallery-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &_title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        text-overflow: clip;
        padding-left: 5px;
        padding-right: 5px;
        min-height: 35.2px;
        text-align: center;
    }
    &_image {
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 30px;
        height: 200px;
    }
    .focus {
        transform: scale(2);
    }
}

.gallery-item:hover, :focus {
    
    transition: 0.2s ease-in-out;
    transform: scale(1.05);
    .gallery-item_image {  
        box-shadow: 0px 2px 5px black;
    }
}