@use '../../utils/variables';

.home-gallery {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    cursor: pointer;

    @media screen and (min-width: 900px) {
        flex-direction: row;
        width: 100%;
        height: calc(100vh - 240px)
    }

    &_primary_item, &_secondary_item {
        position: relative;
        margin-bottom: 30px;
        background-size: cover;
        &-title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            text-overflow: clip;
            cursor: pointer;
            text-align: center;
            text-transform: uppercase;
            color: white;
            z-index: 1;
            padding: 0 20px 0 20px;    
            position: absolute;
            bottom: 15px;
            font-size: 18px;
            font-weight: bold;
            @media screen and (min-width: 900px) {
                text-shadow: 3px 2px 3px #303030;
            }
        }
    }
    &_primary_item {
        background-position: center;
        height: 300px;
        width: 90vw;
        @media screen and (min-width: 900px) {
            width: 70%;
            height: 100%;
            position: relative;
            margin-bottom: 0;
                &-title {
                    font-size: 30px;
                }
        }
    }
    &_secondary {
        
        #div1 {
            height: 300px;
            width: 90vw;
            @media screen and (min-width: 900px) {
                width: 100%;
                height: 50%;
            }
        }
        #div2 {
            height: 300px;
            width: 90vw;       
            @media screen and (min-width: 900px) {
                width: 100%;
                height: 50%;
            }
        }
        @media screen and (min-width: 900px) {
            width: 30%;
            height: 100%;
        }
        &_item {      
            background-position: center;
            @media screen and (min-width: 900px) {
                margin-bottom: 0;
                &-title {
                    font-size: 20px;
                }
            }
        }
    }
}
